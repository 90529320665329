import {
	chainValidators,
	ManagedForm,
	ManagedFieldValidator,
	ManagedValidatorResult,
	validateChecked,
	validateEmail,
	validateMaxLength,
	validateMinLength,
	validateNonEmpty,
	validatePhonenumber,
} from '@web-apps/forms';
import { Translate } from '@web-apps/translations';
import { SignupState, SipgateDomain } from '../../redux/slices/signup';
import { IdentityProvider } from '../../types';
import { isUKPostcode } from '../../utils';
import { validateNoNumber } from '../../utils/validators';
import { useFormikPartialStorage, useFormikSignupData } from './Signup.hooks';

type GlobalFormValues = {
	email: string;
	password: string;
	salutation: string;
	firstname: string;
	lastname: string;
	phoneNumber: string;
	company: string;
	country: string;
	testaccount: boolean;
	zip: string;
	emergencyCalls: boolean;
	city: string;
};

type GermanFormValues = GlobalFormValues & {
	street: string;
	number: string;
};

type InternationalFormValues = GlobalFormValues & {
	address1: string;
	address2: string;
};

export const buildValidatorsByCountryAndDomain = (
	country: string,
	domain: SipgateDomain,
	idp: IdentityProvider,
	hasCitysError: boolean,
	translate: Translate
) => {
	return {
		email: validateEmail(translate('SIGNUP_ERROR_INVALID_EMAIL')),
		password: (input: string): ManagedValidatorResult<string> => {
			if (idp !== 'SIPGATE') {
				return {
					valid: true,
					value: '',
				};
			}
			return validateMinLength(translate('SIGNUP_ERROR_INVALID_PASSWORD'), 8)(input);
		},
		salutation: validateNonEmpty(translate('SIGNUP_ERROR_INVALID_SALUTATION')),
		firstname: chainValidators(
			validateNonEmpty(translate('SIGNUP_ERROR_INVALID_FIRSTNAME')),
			validateNoNumber(translate('SIGNUP_ERROR_NUMBER_NOT_ALLOWED'))
		),
		lastname: chainValidators(
			validateNonEmpty(translate('SIGNUP_ERROR_INVALID_LASTNAME')),
			validateNoNumber(translate('SIGNUP_ERROR_NUMBER_NOT_ALLOWED'))
		),
		phoneNumber: chainValidators(
			validatePhonenumber(translate('SIGNUP_ERROR_INVALID_PHONENUMBER'), {
				allowNonRoutable: true,
				allowDirectDial: false,
				domain: 'sipgate.de',
			})
		),
		company: chainValidators(
			validateNonEmpty(translate('SIGNUP_ERROR_INVALID_COMPANY')),
			validateMaxLength(translate('SIGNUP_ERROR_COMPANY_NAME_LENGTH'), 80)
		),
		country: validateNonEmpty(translate('SIGNUP_ERROR_INVALID_COUNTRY')),
		city: (input: string): ManagedValidatorResult<string> => {
			if (!input) {
				return {
					valid: false,
					error: translate('SIGNUP_ERROR_INVALID_CITY'),
				};
			}
			return validateNonEmpty(translate('SIGNUP_ERROR_INVALID_CITY'))(input);
		},

		emergencyCalls: (input: boolean): ManagedValidatorResult<boolean> => {
			if (domain !== 'sipgate.co.uk') {
				return {
					valid: true,
					value: true,
				};
			}
			return validateChecked(translate)(input);
		},
	};
};

export const buildDeValidators = (
	country: string,
	domain: SipgateDomain,
	idp: IdentityProvider,
	hasCitysError: boolean,
	translate: Translate,
	signupState: SignupState
) => {
	const validators = buildValidatorsByCountryAndDomain(
		country,
		domain,
		idp,
		hasCitysError,
		translate
	);

	const validateZip =
		(t: Translate): ManagedFieldValidator<string, string> =>
		input => {
			if (hasCitysError) {
				return {
					valid: false,
					error: t('SIGNUP_ERROR_INVALID_ZIP'),
				};
			}

			return {
				valid: true,
				value: input,
			};
		};

	const zipValidator = () => {
		if (domain === 'sipgate.de') {
			return chainValidators(
				validateZip(translate),
				validateMinLength(translate('SIGNUP_ERROR_INVALID_ZIP'), 5),
				validateMaxLength(translate('SIGNUP_ERROR_INVALID_ZIP'), 5)
			);
		}

		return validateMaxLength(translate('SIGNUP_ERROR_INVALID_ZIP'), 10);
	};

	const streetValidator = (input: string): ManagedValidatorResult<string> => {
		if (domain === 'sipgate.de') {
			if (!input) {
				return {
					valid: false,
					error: translate('SIGNUP_ERROR_INVALID_STREET'),
				};
			}
			return validateNonEmpty(translate('SIGNUP_ERROR_INVALID_STREET'))(input);
		}

		return {
			valid: true,
			value: input,
		};
	};

	const numberValidator = (input: string): ManagedValidatorResult<string> => {
		if (domain === 'sipgate.de') {
			if (!input) {
				return {
					valid: false,
					error: translate('SIGNUP_ERROR_EMPTY_HOUSE_NUMBER'),
				};
			}

			return validateNonEmpty(translate('SIGNUP_ERROR_EMPTY_HOUSE_NUMBER'))(input);
		}

		return {
			valid: true,
			value: input,
		};
	};

	const initialValues: GermanFormValues = {
		firstname: signupState.firstname || '',
		lastname: signupState.lastname || '',
		email: signupState.email || '',
		password: '',
		zip: signupState.zip || '',
		city: signupState.city || '',
		company: signupState.company || '',
		country,
		phoneNumber: signupState.phoneNumber || '',
		salutation: signupState.gender || '',
		testaccount: false,
		street: signupState.street || '',
		number: signupState.number || '',
		emergencyCalls: domain !== 'sipgate.co.uk',
	};

	const deValidators = {
		firstname: validators.firstname,
		lastname: validators.lastname,
		email: validators.email,
		password: validators.password,
		zip: zipValidator(),
		city: validators.city,
		company: validators.company,
		country: validators.country,
		phoneNumber: validators.phoneNumber,
		salutation: validators.salutation,
		street: streetValidator,
		number: numberValidator,
		emergencyCalls: validators.emergencyCalls,
	};

	const hooks = [useFormikSignupData, useFormikPartialStorage];

	return {
		validators: deValidators,
		initialValues,
		validate: (values: GermanFormValues) =>
			ManagedForm.collectData(deValidators, () => [], values).fieldErrors,
		hooks,
	};
};

export const buildInternationalValidators = (
	country: string,
	domain: SipgateDomain,
	idp: IdentityProvider,
	hasCitysError: boolean,
	translate: Translate,
	signupState: SignupState,
	hasAddressError: boolean
) => {
	const validators = buildValidatorsByCountryAndDomain(
		country,
		domain,
		idp,
		hasCitysError,
		translate
	);

	const validatePostCode =
		(t: Translate): ManagedFieldValidator<string, string> =>
		input => {
			if (hasAddressError || !isUKPostcode(input)) {
				return {
					valid: false,
					error: t('SIGNUP_ERROR_INVALID_ZIP'),
				};
			}

			return {
				valid: true,
				value: input,
			};
		};

	const zipValidator = () => {
		if (country === 'UK' && domain === 'sipgate.co.uk') {
			return validatePostCode(translate);
		}

		return validateMaxLength(translate('SIGNUP_ERROR_INVALID_ZIP'), 10);
	};

	const address1Validator = (input: string): ManagedValidatorResult<string> => {
		if (!input) {
			return {
				valid: false,
				error: translate('SIGNUP_ERROR_INVALID_ADDRESS'),
			};
		}

		return validateNonEmpty(translate('SIGNUP_ERROR_INVALID_ADDRESS'))(input);
	};

	const initialValues: InternationalFormValues = {
		firstname: signupState.firstname || '',
		lastname: signupState.lastname || '',
		email: signupState.email || '',
		password: '',
		zip: signupState.zip || '',
		city: signupState.city || '',
		company: signupState.company || '',
		country,
		phoneNumber: signupState.phoneNumber || '',
		salutation: signupState.gender || '',
		testaccount: false,
		address1: signupState.address1 || '',
		address2: signupState.address2 || '',
		emergencyCalls: domain !== 'sipgate.co.uk',
	};

	const intValidators = {
		firstname: validators.firstname,
		lastname: validators.lastname,
		email: validators.email,
		password: validators.password,
		zip: zipValidator(),
		city: validators.city,
		company: validators.company,
		country: validators.country,
		phoneNumber: validators.phoneNumber,
		salutation: validators.salutation,
		address1: address1Validator,
		emergencyCalls: validators.emergencyCalls,
	};

	const hooks = [useFormikSignupData, useFormikPartialStorage];

	return {
		validators: intValidators,
		initialValues,
		validate: (values: InternationalFormValues) =>
			ManagedForm.collectData<InternationalFormValues, typeof intValidators>(
				intValidators,
				() => [],
				values
			).fieldErrors,
		hooks,
	};
};

export type { GermanFormValues, InternationalFormValues };
